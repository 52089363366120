window.loadAccordion = function () {
    // Remove the no-js class from the <html> element
    document.documentElement.classList.remove('no-js');
    /**
     * Your js...
     */
    // Place any jQuery/helper plugins in here.

    /* Anchor link smooth scroll */
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            if (this.getAttribute('href') !== '#') {
                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        });
    });

    /* Ordered content nav scroll into view */
    document.querySelectorAll('.js-ordered_content__sidenav_item').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            if (this.getAttribute('href') !== '#') {
                const element = document.querySelector(this.getAttribute('href'))
                const yOffset = document.getElementById("js-header").clientHeight;
                const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        });
    });

    /*Accordion jQuery Plugin*/
    var accordionHeaders = document.querySelectorAll('.c-accordion__header');
    var accordionContentBlocks = document.querySelectorAll('.c-accordion-content__wrapper');

    var toggleAccordion = function (accordionIdx, leaveOpen) {
        //go thru each accordion content block
        for (var i = 0; i < accordionContentBlocks.length; i++) {
            //check if this the one that has been clicked on
            if (i === accordionIdx) {
                //this is the current one, so close if open or open if closed
                var thisContentBlock = accordionContentBlocks[i];
                // get current heigth
                var currentHeight = thisContentBlock.clientHeight;
                if (currentHeight > 0) {
                    //if more than zero, is open, so close
                    thisContentBlock.style.height = "0px";
                    accordionHeaders[i].classList.remove('c-accordion__header--active');
                } else {
                    //is zero, is closed, so open
                    thisContentBlock.style.height = thisContentBlock.scrollHeight + 'px';
                    accordionHeaders[i].classList.add('c-accordion__header--active');

                }
            } else {
                //is another accordion so just close
                if (!leaveOpen) {
                    accordionContentBlocks[i].style.height = 0;
                    accordionHeaders[i].classList.remove('c-accordion__header--active');

                }

            }
        }
    }
    var resizeDebounceTimer;
    var resizeOpenAccordion = function () {
        clearTimeout(resizeDebounceTimer);
        resizeDebounceTimer = setTimeout(function () {
            for (var i = 0; i < accordionContentBlocks.length; i++) {
                var thisContentBlock = accordionContentBlocks[i];
                if (thisContentBlock.clientHeight > 0) {
                    thisContentBlock.style.height = thisContentBlock.scrollHeight + 'px';
                }
            }
        }, 500);

    }

    if (accordionHeaders.length) {
        //if we have accordions, attach an event listener to them
        for (var i = 0; i < accordionHeaders.length; i++) {
            var parentAccordion = findAncestor(accordionHeaders[i], 'js-accordion');
            var leaveOpen = parentAccordion.getAttribute('data-leaveopen').toLowerCase() === "true" ? true : false;
            accordionHeaders[i].addEventListener("click", toggleAccordion.bind(null, i, leaveOpen));
            if (accordionHeaders[i].classList.contains('c-accordion__header--active')) {
                toggleAccordion(i);
            }
        }
        window.addEventListener('resize', resizeOpenAccordion, false);
    }

    function findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }
    /*End of accordion jQuery plugin*/

    /* Ordered Content Module 
       On scroll set top most visible item as active in the modules side nav.*/

    //Check if an element is visable in viewport
    function isScrolledIntoView(el) {
        var rect = el.getBoundingClientRect();
        const headerOffset = document.getElementById("js-header").clientHeight;
        return (rect.top >= headerOffset) && (rect.bottom <= window.innerHeight);
    }

    function updateActiveNavItem(activeIndex, orderedContentItems, orderedContentNavItems) {
        //Mobile
        Array.from(orderedContentItems).forEach(
            function (element, index, array) {
                if (activeIndex === index) {
                    element.classList.add('active');
                } else {
                    element.classList.remove('active');
                }
            }
        );

        //Ordered items navbar 
        Array.from(orderedContentNavItems).forEach(
            function (element, index, array) {
                if (activeIndex === index) {
                    element.classList.add('active');
                } else {
                    element.classList.remove('active');
                }
            }
        );
    }

    //Get ordered content module container

    document.querySelectorAll('.js-ordered_content').forEach(function (orderedContentModule) {

        var orderedContentItems = orderedContentModule.getElementsByClassName("js-ordered_content__item");
        var orderedContentNavItems = orderedContentModule.getElementsByClassName("js-ordered_content__sidenav_item");

        var activeItem = 0;
        updateActiveNavItem(activeItem, orderedContentItems, orderedContentNavItems);

        // On scroll set top most visible item as active.
        window.addEventListener('scroll', function () {
            //Get list of all visable items 
            var visible = [];
            Array.from(orderedContentItems).forEach(
                function (element, index) {
                    if (isScrolledIntoView(element)) {
                        visible.push(index);
                    };
                }
            );
            //Get top most item
            var topItem = visible[0];
            //If top item has changed then update the active item in nav.
            if (topItem != null && activeItem !== topItem) {
                activeItem = topItem;
                updateActiveNavItem(activeItem, orderedContentItems, orderedContentNavItems);
            }
        });
    });
}

loadAccordion();

//JS side nav expand subnav when child page selected
document.querySelectorAll('.js-side_nav__item').forEach(function (item) {
    if (item.querySelectorAll('.selected').length > 0) {
        item.classList.add('js-side_nav__open');
    }
});


// *Page Tabs JS*/
var tab = {
    nav: null, // holds all tabs
    txt: null, // holds all text containers
    init: function () {
        // tab.init() : init tab interface
        if (document.getElementById("js-tab-nav")) {
            // Get all tabs + contents
            tab.nav = document.getElementById("js-tab-nav").getElementsByClassName("js-tabnav");
            tab.txt = document.getElementById("js-tab-contents").getElementsByClassName("js-tabtxt");

            // Error checking
            if (tab.nav.length === 0 || tab.txt.length === 0 || tab.nav.length !== tab.txt.length) {
                console.log("ERROR STARTING TABS", tab.nav, tab.txt);
            } else {
                // Attach onclick events to navigation tabs
                for (var i = 0; i < tab.nav.length; i++) {
                    tab.nav[i].dataset.pos = i;
                    tab.nav[i].addEventListener("click", tab.switch);
                }

                // Default - show first tab
                if (typeof currentPageIndex == "undefined") {
                    tab.nav[0].classList.add("active");
                    tab.txt[0].classList.add("active");
                }
            }
        }
    },

    switch: function (event) {
        // tab.switch() : change to another tab
        event.preventDefault();

        // Hide all tabs & text
        for(var i = 0; i < tab.nav.length; i++) {
            tab.nav[i].classList.remove("active");
        }

        for(var i = 0; i < tab.txt.length; i++) {
            tab.txt[i].classList.remove("active");
        }

        // Set current tab
        tab.nav[this.dataset.pos].classList.add("active");
        tab.txt[this.dataset.pos].classList.add("active");
        window.landBlockDetails.LoadPowerBi();
    }
};

window.addEventListener("load", tab.init);

const tabbedNavigtaion = document.querySelector('#js-tab-swiper-container');


function initialiseTabs() {
    if (tabbedNavigtaion) {
        var initialSlideIndex = 0;
        if (typeof currentPageIndex != "undefined") {
            initialSlideIndex = currentPageIndex;
        }
        var swiper = new Swiper('.js-tab-swiper-container', {
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 0,
            initialSlide: initialSlideIndex,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            
            breakpoints: {
                480: {
                    centeredSlides: true,
                    centeredSlidesBounds: true
                }
                
            }
        });
        tab.init();
    }
}

var landBlockDetailsPage = document.getElementById("vue-land-block-details");
if (!landBlockDetailsPage) {
    initialiseTabs();
}

/** Fade full page overlay **/
function fadeIn() {
    var element = document.getElementById("js-page-container");
    element.classList.add("o-page--fade-active");
}

function fadeOut() {
    var element = document.getElementById("js-page-container");
    element.classList.remove("o-page--fade-active");
}


// Toggle hidden div by id 
function toggleHidden(id) {
    var el = document.getElementById(id);
    var attr = document.getElementById(id).attributes;

    el.classList.toggle("hidden");
    if (attr['aria-hidden'].value === "true") {
        document.getElementById(id).setAttribute("aria-hidden", "false");
    } else {
        document.getElementById(id).setAttribute("aria-hidden", "true");
    }
}

// Toggle hidden video transcript by id 
function toggleHiddenVideo(id, e) {
    e.target.classList.toggle("hidden");
    var el = document.getElementById(id);
    var attr = document.getElementById(id).attributes;
    el.classList.toggle("hidden");
    if (attr['aria-hidden'].value === "true") {
        document.getElementById(id).setAttribute("aria-hidden", "false");
    } else {
        document.getElementById(id).setAttribute("aria-hidden", "true");
    }
}

// Script to allow equal heights headers in columns, where column and header height both unknown
function equalHeights(className) {
    var findClass = document.getElementsByClassName(className);
    var tallest = 0;

    //remove any existing min heights
    for (var i = 0; i < findClass.length; i++) {
        findClass[i].style.minHeight = 'unset';
    }
    // Loop over matching divs
    for (var i = 0; i < findClass.length; i++) {
        var ele = findClass[i];
        var eleHeight = ele.offsetHeight;
        tallest = (eleHeight > tallest ? eleHeight : tallest);
    }
    // Add min height across all matching divs
    for (var i = 0; i < findClass.length; i++) {
        findClass[i].style.minHeight = tallest + "px";
    }
}

// Card headers on hoempage
function updateHeights() {
    equalHeights('js-media-card__header');
    equalHeights('js-footer_nav__section_headings');
}

updateHeights();
window.addEventListener('resize', function () {
    updateHeights();
}, false);

//Popper JS
document.addEventListener('DOMContentLoaded', function () {
    var tooltipElements = document.getElementsByClassName("js-tooltip");


    Array.from(tooltipElements).forEach(function (trigger) {
        new Tooltip(trigger, {
            title: trigger.getAttribute('data-tooltip')
        });
    });
    
});

// Swiper on process flow page
var swiper = new Swiper('#js-process_flow_banner', {
    slidesPerView: 'auto',
    spaceBetween: 10,
});


// Adding a span to all styled links within TPK
// The inner span element is required to allow inline links to wrap and retain their bottom border

function styleLinks() {
    var links = document.querySelectorAll(".u-internal-link,.u-internal-link--back, .u-external-link, .u-download-link, .u-internal-link--caret, .c-rich-text-block a");
    for (var i = 0; i < links.length; i++) {
        var org_html = links[i].innerHTML;
        var new_html = "<span>" + org_html + "</span>";
        links[i].innerHTML = new_html;

        //If a link belongs to a pdf page, display the full url directly below the link
        if (document.getElementById("js-print-pdf-page")) {
            links[i].insertAdjacentHTML('afterend', '<div class="pdf-link-text">' + links[i].href + '</div>');
        }
    }
}
styleLinks();


// Scroll to top
var btnScroll = document.getElementById("js-btn_back_to_top");
var oldScroll = 0;
if (btnScroll) {
    // Display scroll up button on scroll > 400px

    window.onscroll = function() {
        var height = window.scrollY || document.documentElement.scrollTop;
        if (height > 400) {
            btnScroll.classList.add('show');
            btnScroll.classList.remove('hide');
        } else {
            btnScroll.classList.remove('show');
            btnScroll.classList.add('hide');
        }

        if(window.innerWidth < 1200) {
            if (oldScroll > height) {
                btnScroll.style.opacity = 1;
                oldScroll = height;
            } else {
                btnScroll.style.opacity = 0.7;
                oldScroll = height;
            }
        }
    };

    // On click scroll to top
    btnScroll.addEventListener('click', function() {
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })});

    // Set position of scroll button dynamically

    /*
    function setScrollBtnPosition() {
        var w = window.innerWidth; 
        var btnWidth = btnScroll.offsetWidth + 10;
        var outsideGutter = 20;
        var insideGutter = 10;

        //Determin if button should sit outside main container
        if (window.innerWidth < (1206 + ((btnWidth+outsideGutter)*2))) {
            btnScroll.style.setProperty('right', insideGutter + "px")
        } else {
            var rm = (window.innerWidth - 1206) / 2;
            var outsidePosition = rm -  (btnWidth+outsideGutter);
            btnScroll.style.setProperty('right', outsidePosition + "px")
        }
    }

    setScrollBtnPosition();
    
    //window.addEventListener("resize", setScrollBtnPosition);
    */
}

var stickyMap = document.getElementById("js-landblock__results_sticky");
var header = document.getElementById("js-header");
if (stickyMap && header) {
    stickyMap.style.top = header.offsetHeight + "px";
}

/** Set sticky header in IE11 **/
stickybits("#js-header");